import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

export const PageWrapper = styled.div`
  display: block;
  margin: 4rem auto 8rem auto;
  > * {
    margin: 0 auto !important ;
    text-align: center;
  }
  @media (min-width: ${breakpoints.lg}) {
    > * {
      max-width: 40rem;
    }
  }
  @media (min-width: ${breakpoints.sm}) {
    margin: 8rem auto;
  }
`;
export const Err = styled.p`
  color: ${colors.darkGray};
  font-size: 1.4rem;
`;
export const ErrCode = styled.p`
  font-size: 8rem;
  font-weight: 700;
`;
export const ErrMsg = styled.p`
  padding: 1rem;
`;

export const ErrTitle = styled.p`
  font-size: 2rem;
`;
export const ButtonWrapper = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 2rem;
  }
`;
